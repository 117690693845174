<template>
  <div>
    <v-date-picker class="inline-block h-full" :masks="masks" v-bind="$props" @dayclick="onDayClick" ref="picker" locale="de">
      <template v-slot="{togglePopover}">
        <div class="flex items-center">
          <b-input-group :size="size">
            <b-form-input type="text"
                          class="form-control"
                          v-bind:state="state"
                          v-bind:value="formattedTime"
                          :placeholder="i18nFormatKey"
                          @change="onDateInput"
                          :disabled="disabled"

            />
            <b-input-group-append>
              <b-button :size="size" text="Button" variant="primary" @click="togglePopover()"><i
                  class="far fa-calendar-alt"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: "calendarPicker",
  data: () => ({
    masks: {
      L: 'DD/MM/YYYY',
      input: ['DD/MM/YYYY'],
      // ...optional `title`, `weekdays`, `navMonths`, etc
    }
  }),
  props: {
    value: {
      required: true,
      default: () => null
    },
    mode: {
      required: false,
      default: () => "date"
    },
    disabled: {
      required: false,
      default: () => false
    },
    state: {},
    size: {
      required: false,
      default: () => "sm"
    },
    minDate: {}, maxDate: {}, disabledDates: {}, availableDates: {}
  },
  computed: {
    formattedTime() {
      let momentTime = this.$moment(this.value, this.i18nInputKey);
      if (!momentTime.isValid())
        return "";
      return momentTime.format(this.i18nFormatKey)
    },
    i18nMaskKey() {
      return '##/##/####';
    },
    i18nFormatKey() {
      return 'DD/MM/YYYY';
    },
    i18nInputKey() {
      return 'YYYY-MM-DD';
    },
  },
  methods: {
    setInput(val) {
      this.$emit('input', val)
    },
    onDateInput(event) {
      let value = "";
      if(typeof event === "string"){
        value = event;
      }
      else {
        value = event.target.value;
      }
      if (value == null || value.trim() === "") {
        this.setInput(value);
        return;
      }
      let momentTime = this.$moment(value, this.i18nFormatKey);
      if (!momentTime.isValid())
        return;
      let isDisabled = this.$refs['picker'].valueIsDisabled(momentTime.toDate())
      if (isDisabled) {
        console.log("onDateInput -> date is invalid", {event, time: value})
        return;
      }
      this.setInput(momentTime.format(this.i18nInputKey));
    },
    onDayClick(event) {
      this.setInput(this.$moment(event.date).format(this.i18nInputKey));
    }
  }
}
</script>

<style>
.vc-svg-icon{
  transform: rotate(
      180deg);
}
</style>
