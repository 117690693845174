export default {
    namespaced: true,

    getters: {},
    actions: {
        getMessagesByReservationId(actions, {contractId}) {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().get('/hostaway/owner/contract/hostawayMessages/' + contractId)
                    .then(response => {
                        resolve(response.data.objects)
                    })
                    .catch(error => reject(error))
            });
        },
        getUnseenMessages() {
            let $this = this;
            return new Promise(function (resolve, reject) {
                $this.$api.clientApi().post('/hostaway/owner/contract/unseen')
                    .then(response => {
                        resolve(response.data.objects)
                    })
                    .catch(error => reject(error))
            });
        },

    }
}
