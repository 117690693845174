import Vue from 'vue'
import Router from 'vue-router'
import Layout from './Layouts/LayoutFull'
import LayoutClient from './Layouts/LayoutClient'
import store from './store'

Vue.use(Router)

export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            alias: '/home',
            component: Layout,
            children: [{
                path: '',
                name: 'Calendar',
                component: () => import('./views/Home.vue')
            }]
        },
        {
            path: '/auth',
            component: Layout,
            children: [{
                path: 'login',
                alias: '',
                name: 'Login',
                component: () => import('./views/auth/Login.vue')
            },
                {
                    path: 'forgot-password',
                    name: 'ForgotPassword',
                    component: () => import('./views/auth/ForgotPassword.vue')
                }]
        },
        {
            path: '/dashboard',
            component: LayoutClient,
            children: [{
                path: 'dashboard',
                alias: '',
                name: 'dashboard',
                component: () => import('./views/dashboard/Dashboard.vue')
            }]
        },
       {
            path: '/calendar',
            component: LayoutClient,
            children: [{
                path: 'calendar',
                alias: '',
                name: 'calendar',
                component: () => import('./views/calendar/CalendarRentSite.vue')
            }]
        },
        {
            path: '/contract',
            component: LayoutClient,
            children: [
                {
                    path: '',
                    alias: 'all',
                    name: 'MyContracts',
                    component: () => import('./views/contract/contracts.vue')
                },{
                path: 'view/:id',
                name: 'contractView',
                component: () => import('./views/contract/contractView.vue')
            }]
        },
        {
            path: '/monetary',
            component: LayoutClient,
            children: [
                {
                    path: '',
                    alias: 'all',
                    name: 'Payments',
                    component: () => import('./views/monetary/payments.vue')

            }]
        },  {
            path: '/prices',
            component: LayoutClient,
            children: [
                {
                    path: '',
                    alias: '',
                    name: 'Pricing',
                    component: () => import('./views/monetary/pricing.vue')

            }]
        },
        {
            path: '/realestates',
            component: LayoutClient,
            children: [
                {
                    path: '',
                    alias: 'all',
                    name: 'Real estates',
                    component: () => import('./views/realestate/realEstates.vue')

            }, {
                    path: 'edit/:id',
                    name: 'Edit Real estate',
                    component: () => import('./views/realestate/editRealEstate')
                }]
        },
    ]
})

import $ from 'jquery'
const publicPages = ['/auth']
router.beforeEach((to, from, next) => {
    if(publicPages.findIndex(page=>to.fullPath.startsWith(page)) >= 0 )
        return next();
// redirect to login page if not logged in and trying to access a restricted page
    store.dispatch('client/checkConnection', to)
        .then(function (path) {
            $('#loading')
                .hide()

            if (path !== undefined && to.fullPath === path) {
                return next()
            }
            next(path)
        })
        .catch(function (path) {
            $('#loading')
                .hide()

            if (path !== undefined) {
                return next('/auth/login?returnUrl=' + to.fullPath)
            }

            next(path)
        })
})


