import Vue from 'vue'
import Vuex from 'vuex'

import client from '@/stores/client.js'
import contract from '@/stores/contract.js'
import notifications from '@/stores/notifications.js'
import realEstates from "@/stores/realEstates.js";
import calendar from "@/stores/calendar.js";
import monetary from "@/stores/monetary.js";
import document from "@/stores/document.js";
import hostaway from "@/stores/hostaway.js";

Vue.use(Vuex);



export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {client,contract,notifications,document,realEstates,monetary,calendar,hostaway}
});


//AUTO INSERT $t
Vue.mixin(
    {
        computed: {
            currentFormattedLang() {
                return "en_US";
            }
        },
        methods: {
            mergeSrcUrl(base,attachment){
                if(attachment == null  )
                    return null;
                if(attachment.attachmentUrl.startsWith("http"))
                    return attachment.attachmentUrl;
                return base+attachment.attachmentUrl;
            },
            downloadDocument(data, fileName) {
                console.log("downloadDocument()",{data, fileName})
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                window.document.body.appendChild(link);
                link.click();
                window.document.body.removeChild(link);
            },
            asyncDownloadFile(file) {
                let link = window.document.createElement("a");
                console.log("getDocument", file)
                // If you don't know the name or want to use
                // the webserver default set name = ''
                link.setAttribute('download', file.fileName);
                link.href = URL.createObjectURL(file.data);
                window.document.body.appendChild(link);
                link.click();
                link.remove();
            },
        }
    });
