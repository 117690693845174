import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from "vuex";

Vue.use(VueAxios, axios)

let config = window.config;

let interceptors = {
    response(response) {
        return response;
    },
    error(error) {
        if (error.response == null || error.response.data == null) {
            error.response = {data: {status: false, message: "connection failed"}}
        }
        return Promise.reject(error);
    }
}

let api = {
    internal() {
        return axios.create();
    },
    branchApi() {
        return axios.create({
            baseURL: config.apiUrl + "api/branch/",
            timeout: 100000,
            headers: {'branch': config.branchId, "Authorization": `Bearer ${config.branchToken}`}
        })
    },
    clientApi() {
        let instance = axios.create({
            baseURL: window.config.apiUrl + "api/client/",
            timeout: 100000,
            headers: {
                'branch': window.config.branchId,
                "Authorization": `Bearer ${localStorage.Bearer || ''}`,
                "uniqueId": (localStorage.uniqueId || '')
            },
        })
        instance.interceptors.response.use(interceptors.response, interceptors.error);
        return instance;
    },
    getClientHeaders(){
        return {
            'branch': window.config.branchId,
            "Authorization": `Bearer ${localStorage.Bearer || ''}`,
            "uniqueId": (localStorage.uniqueId || '')
        };
    },
    downloadApi() {
        let instance = axios.create({
            baseURL: window.config.apiUrl + "api/client/",
            responseType:  'arraybuffer',
            timeout: 100000,
            headers: {
                'branch': window.config.branchId,
                "Content-Disposition": "attachment",
                "Authorization": `Bearer ${localStorage.Bearer || ''}`,
                "uniqueId": (localStorage.uniqueId || '')
            },
        })
        instance.interceptors.response.use(interceptors.response, interceptors.error);
        return instance;
    },
};

Vue.use({
    install(Vue) {
        Vue.prototype.$api = api;
    }
})

Vuex.Store.prototype.$api = api;



