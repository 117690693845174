import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import store from './store'
import i18n from './Plugins/vue-i18n'

Vue.config.productionTip = false

require('./Plugins/custom-prototypes');
require('./Plugins/bootstrap');
require('./Plugins/vee-validate');
require('./Plugins/vue-ctk-date-time-picker');
require('./Plugins/vSelect');
require('./Plugins/v-calendar');
require('./Plugins/axios');
require('./Plugins/vue-moment');
require('./Plugins/vue-feather-icons');
require('./Plugins/vue2-perfect-scrollbar');
require('./Plugins/vue-form-wizard');
require('./Plugins/vue-toastification');
require('./Plugins/vue-signature-pad');
require('./Plugins/vue-slick-carousel');
require('./Plugins/vue-country-flag');
require('./Plugins/vue-ling');

export const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
